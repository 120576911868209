
import Vue from 'vue';
import Component from 'vue-class-component';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { Prop } from 'vue-property-decorator';
import { Containers } from '@/settings/domain/containers/Containers';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
}

@Component({
  name: 'ShippingDetails',
  components: {}
})
export default class ShippingDetails extends Vue {
  @Prop() form!: Bulk;
  @Prop() readonly lists!: Lists;
  @Prop() readonly forView!: boolean;
  @Prop({ default: true }) readonly showCommodity!: boolean;

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }
}
